<template>
  <div class="filepondWrapper">
    <file-pond
      name="file"
      ref="pond"
      :label-idle="idleLabel"
      allow-multiple
      :maxFiles="maxFiles ? maxFiles : undefined"
      credits="false"
      :server="{process: process, revert: revert}"
      :files="files"
      @init="handleFilePondInit"
      @warning="uploadWarning($event)"
      @processfiles="updateValue"
      @updatefiles="updateValue"
    />
    <ValidationWarning :valid="valid" :text="errorText" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'
import { makeDeleteRequest, makeUploadRequest } from '@/utils/cloudinary/cloudinaryHelper'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType
)

export default {
  name: 'SquareFileUpload',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    },
    question: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Number,
      default: 0
    },
    maxFiles: {
      type: Number,
      default: null
    },
    // The maximum size in pixels for both the image's width and height
    maxImageSize: {
      type: Number,
      default: 1920
    },
    title: {
      type: String,
      default: ''
    },
    idleLabel: {
      type: String,
      default: '<div class="simple-upload-button">Upload floorplan</div>'
    },
    handleFilePondInit: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      valid: true,
      output: [],
      defaultErrorText: `Please upload at least ${this.required} files.`,
      errorText: '',
      files: []

    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  mounted () {
    this.errorText = this.defaultErrorText
    this.output = this.modelValue || []

    // Initialize with any existing files
    if (this.modelValue && this.modelValue.length > 0) {
      this.files = this.modelValue.map(file => ({
        source: file.url,
        options: {
          type: 'local',
          metadata: {
            serverId: file.id
          }
        }
      }))
    }
  },
  // watch: {
  //   modelValue: {
  //     handler (newValue) {
  //       if (!newValue) return

  //       // Only update if changed to avoid loops
  //       if (JSON.stringify(this.output) !== JSON.stringify(newValue)) {
  //         this.output = newValue

  //         // When files are added from parent component
  //         if (this.$refs.pond && newValue.length) {
  //           const currentFiles = this.$refs.pond.getFiles().map(file => file.serverId || '')
  //           const newFiles = newValue.filter(file => !currentFiles.includes(file.id))

  //           // Add any new files that aren't already in the pond
  //           if (newFiles.length) {
  //             newFiles.forEach(file => {
  //               this.$refs.pond.addFile(file.url, {
  //                 type: 'local',
  //                 metadata: { serverId: file.id }
  //               })
  //             })
  //           }
  //         }
  //       }
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    validate () {
      this.valid = !(this.required && this.$refs?.pond?.getFiles().length < this.required)
      return this.valid
    },
    uploadWarning (event) {
      if (event?.body === 'Max files') {
        this.valid = false
        this.errorText = `Please upload between ${this.required} and ${this.maxFiles} files.`
      }
    },
    resetError () {
      this.errorText = this.defaultErrorText
      this.valid = true
    },

    setError (message) {
      this.valid = false
      this.errorText = message
    },

    async remove (error, file) {
      if (!this.$refs.pond) return

      this.resetError()
      if (error) {
        return
      }
      this.$refs.pond.removeFile(file.id, { revert: true })

      await this.updateValue()
    },

    async updateValue () {
      if (!this.$refs.pond) return
      this.resetError()
      const files = this.$refs.pond.getFiles()
      const output = []

      for (const i in files) {
        // const fileData = {
        //   id: files[i]?.serverId,
        //   url: files[i]?.source.url
        // }
        // output.push(fileData)

        // // Check if the file has the correct structure
        const sourceUrl = files[i]?.source?.url
        const serverId = files[i]?.serverId

        // Handle case where serverId contains the URL and url is missing

        if (serverId && serverId.startsWith('http') && !sourceUrl) {
          const itemId = this.output.find(o => o.url === serverId).id
          // Extract a proper ID from the URL
          const urlParts = serverId.split('/')
          const fileId = itemId || urlParts[urlParts.length - 1].split('?')[0]

          // Create file data with proper structure
          const fileData = {
            id: fileId || `file_${Date.now()}`, // Use filename as ID, or timestamp if not available
            url: serverId // Use the URL from serverId
          }
          output.push(fileData)
        } else {
          // Normal case - create file data with standard structure
          const fileData = {
            id: serverId || `file_${Date.now()}`,
            url: sourceUrl
          }
          output.push(fileData)
        }
      }

      this.output = output

      this.$emit('update:modelValue', this.output)
    },

    // fileUploadMethods
    revert (token, successCallback, errorCallback) {
      makeDeleteRequest({
        token,
        successCallback,
        errorCallback
      })
    },

    process (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
      transfer,
      _options
    ) {
      const abortRequest = makeUploadRequest({
        file,
        fieldName,
        successCallback: load,
        errorCallback: error,
        progressCallback: progress
      })

      return {
        abort: () => {
          abortRequest()
          abort()
        }
      }
    }
  },
  components: {
    ValidationWarning,
    FilePond
  }
}
</script>

<style lang="scss">
.filepondWrapper {
  width: 100%;
  min-width: 150px;
  margin-bottom: 40px; /* Add margin to prevent footer overlap */

  & .filepond--root {
    height: auto !important;
    max-height: 100px; /* Limit height to prevent overlap */
    border: none;
    background: transparent !important;
  }

  .title {
    width: 100%;
    text-align: center;
    font-family: Söhne, serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 12px;
    color: #333;
  }
}

.filepond--root {
  font-family: Söhne, Arial, sans-serif;
  border: none;

  .filepond--drop-label {
    color: transparent; /* Hide any text */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  /* Override any native FilePond styling */
  .filepond--panel-root {
    background-color: transparent;
    display: none;
  }

  .filepond--drip-blob {
    display: none;
  }

  /* When files are uploaded, show the list */
  .filepond--list-scroller {
    margin-top: 15px;
  }

  /* the background color of the file and file panel */
  .filepond--item-panel {
    background-color: #005a24;
  }

  /* the background color of the black action buttons */
  .filepond--file-action-button {
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* the icon color of the black action buttons */
  .filepond--file-action-button {
    color: white;
  }

  /* the text color of the file status and info labels */
  .filepond--file {
    color: white;
  }

  /* error state color */
  [data-filepond-item-state*='error'] .filepond--item-panel,
  [data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: red;
  }

  [data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: green;
  }
}

/* Custom styling for green button */
.simple-upload-button {
  background-color: #004021;
  color: white;
  padding: 12px 24px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  border: none;
  font-size: 16px;
  transition: background-color 0.2s;
  max-width: 100%;
  width: 240px !important;
  margin-bottom: 35px;
  &:hover {
    background-color: #004a1e;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}
</style>
