<template>
  <Loader :visible="loading" fixed/>
  <div v-if="!loading && targetSurvey" >
    <div class="headerWrapper">
      <h3 class="header">The Specs</h3>
      (Optional)
      <div class="divider"/>
    </div>
    <div class="wrapper">
      <div class="description">
        Great furniture isn't so great if it doesn't fit. Taking a few minutes to provide some sizes/measurements now can save time, energy and potentially money later.<br/><br/>
        While this is <strong>optional at this point</strong>, we highly recommend it!
      </div>
      <div class="divider"/>
      <span class="sectionsDesc">We offer three simple options:</span>

      <div class="sectionWrapper">
        <p class="sectionTitle"><strong>1.</strong> Know what sizes you need? Plug them in here.</p>
        <div class="section">
          <div class="sizesWrapper">
            <div class="furnitureSize" v-for="(furniture, index) in furnituresList" :key="index">
              <span v-if="['rug','sofa','sideboard','nightstand','dresser','dining_table'].includes(furniture)" class="sizeLabel furniture">{{ furniture.replace("_", " ") }}: </span>
              <div v-if="['rug','sofa','sideboard','nightstand','dresser','dining_table'].includes(furniture)" class="textInputWrapper wide">
                <TextInput
                  v-model="localMeasurements[`${furniture}A`]"
                  variant="outlined"
                />
                <span class="sizeSeparator">length</span>
                <TextInput
                  v-model="localMeasurements[`${furniture}B`]"
                  variant="outlined"
                />
                <span class="sizeSeparator">width</span>
                <span v-if="furniture==='rug'" class="sizeSeparator">&nbsp;&nbsp;feet</span>
                <span v-else class="sizeSeparator">&nbsp;&nbsp;inches</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sectionWrapper">
        <p class="sectionTitle"><strong>2.</strong> Have a floorplan? Upload it here:</p>
        <div class="fileUploadContainer">
          <div class="fileUploadWrapper">
            <SquareFileUpload
              title="Floor Plan"
              :handleFilePondInit="handleFilePondInit"
              v-model="floorPlans"
              ref="floorPlansUploader"
            />
          </div>
        </div>
      </div>

      <div class="rendrSectionWrapper">
        <p class="sectionTitle"><strong>3.</strong> Have an iPhone/iPad? Click or scan using our scanning app.</p>
        <div class="qrCodeWrapper">
          <img src="../../../assets/images/rendr-logo.svg" alt="Rendr logo"/>
          <p class="compatibleWithDevices mobileOnly">Compatible with: iPhone 12 - 15 Pro / Max, iPad (2020 and later)</p>
          <a class="scanButton rendrCta"
            :href="`${baseAppUrl}/scan-with-rendr?user=${currentUser.uid}&survey=${targetSurvey.id}`"
            target="_blank">
            <img src="@/assets/images/app-store.svg" alt="app-store">
          </a>

          <div class="qrCode">
            <p class="compatibleWithDevices">Compatible with: iPhone 12 - 15 Pro / Max, iPad (2020 and later)</p>
            <VueQRCodeComponent
              :text="`${baseAppUrl}/scan-with-rendr?user=${currentUser.uid}&survey=${targetSurvey.id}`"
              size="160">
            </VueQRCodeComponent>
          </div>
        </div>
      </div>

      <button
        @click="saveSurvey"
        class="primary customGreenBTN measurementsSave"
      >
        Save
      </button>
      <div class="quizNavigationWrapper">
        <button
        @click="()=>{this.$router.push({
          path: '/projects'
        })} "
        class="quiz__navigation__button quiz__navigation__button--back"
      >
        <span class="icon-arrow-left"></span> Back to Projects
      </button>
      </div>
    </div>

    <PhotosPreview
      :images="floorPlans"
      :onFileRemove="(file) => removeFile(file.id)"
    />

    <ExamplesModal
      :onClose="closeExamplesModal"
      v-if="showExamplesModal"
    />

    <ValidationWarning :valid="!errorText" :text="errorText"/>
  </div>
</template>

<script>
import SquareFileUpload from '@/components/quiz/PhotosAndMeasurements/components/SquareFileUpload.vue'
import ExamplesModal from '@/components/quiz/PhotosAndMeasurements/components/ExamplesModal.vue'
import PhotosPreview from '@/views/PhotosPreview.vue'
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import TextInput from '@/components/quiz/questions/TextInput.vue'
import Loader from '../../../components/Loader.vue'
export default {
  components: {
    ValidationWarning,
    PhotosPreview,
    ExamplesModal,
    SquareFileUpload,
    TextInput,
    Loader
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'targetSurvey'
    ]),
    ...mapState(['fbFunctions']),
    baseAppUrl: function () {
      return process.env.VUE_APP_ROOT_URL
    },
    furnituresList () {
      console.log(this.targetSurvey?.responses?.furnitures)

      // Safely access furnitures with a fallback to empty array
      return this.targetSurvey?.responses?.furnitures || []
    }
  },

  data () {
    return {
      loading: true,
      showExamplesModal: false,
      roomPhotos: [],
      floorPlans: [],
      localMeasurements: {}, // This will store our measurements
      errorText: null,
      initialized: false,
      lastProcessedFloorplan: null
    }
  },
  methods: {
    ...mapActions(['updateFirestoreCurrentQuiz']),
    ...mapMutations([
      'setGlobalNotification'
    ]),
    closeExamplesModal () {
      this.showExamplesModal = false
    },
    openExamplesModal () {
      this.showExamplesModal = true
    },
    handleFilePondInit () {
      const element = document.querySelector('#RoomMeasurementsExample')
      if (element) {
        element.addEventListener('click', (e) => {
          e.preventDefault()
          this.openExamplesModal()
        })
      }
    },
    removeFile (file) {
      // Check if file is an object or just an ID
      const fileId = typeof file === 'object' ? file.id : file

      // Find if file exists in floorPlans by ID
      const floorPlanIndex = this.floorPlans
        ? this.floorPlans.findIndex(item => item.id === fileId) : -1

      if (floorPlanIndex !== -1) {
        // Temporarily disable watcher by setting a flag
        this._isRemoving = true

        try {
          // Create a new array without the file (for reactivity)
          const newFloorPlans = [...this.floorPlans]
          newFloorPlans.splice(floorPlanIndex, 1)

          // Update floorPlans directly
          this.floorPlans = newFloorPlans

          // If the uploader reference exists, try to remove the file there too
          if (this.$refs.floorPlansUploader && this.$refs.floorPlansUploader.$refs && this.$refs.floorPlansUploader.$refs.pond) {
            // Get all files from FilePond
            const pondFiles = this.$refs.floorPlansUploader.$refs.pond.getFiles()

            // Find matching file in FilePond
            const pondFileIndex = pondFiles.findIndex(f =>
              (f.serverId === fileId) ||
          (f.source && f.source.url === fileId)
            )

            // If found, remove directly from FilePond
            if (pondFileIndex !== -1) {
              this.$refs.floorPlansUploader.$refs.pond.removeFile(pondFiles[pondFileIndex].id)
            }
          }

          return true
        } finally {
          // Re-enable watcher
          this._isRemoving = false
        }
      } else {
        console.warn('File not found in floorPlans:', fileId)
        return false
      }
    },
    async validate () {
      if (this.floorPlans.some(img => !img.url) || this.roomPhotos.some(img => !img.url)) {
        this.errorText = 'Please wait for your images to upload before proceeding'
        return false
      }
      return true
    },
    async saveSurvey () {
      const furnitureToProcess = ['rug', 'sofa', 'sideboard', 'nightstand', 'dresser', 'dining_table']

      furnitureToProcess.forEach(furniture => {
        const dimensionA = this.localMeasurements[`${furniture}A`]
        const dimensionB = this.localMeasurements[`${furniture}B`]

        if (dimensionA !== undefined && dimensionB !== undefined) {
          this.localMeasurements[furniture] = `${dimensionA}x${dimensionB}`

          delete this.localMeasurements[`${furniture}A`]
          delete this.localMeasurements[`${furniture}B`]
        }
      })

      const measurementsString = this.localMeasurements && Object.keys(this.localMeasurements).length > 0
        ? JSON.stringify(this.localMeasurements)
        : ''

      // Format floorplan data with nice formatting
      const formattedFloorPlans = this.floorPlans
        .filter(item => item && item.url)
        .map(item => ({
          id: item.id || item.url,
          url: item.url
        }))

      // Create a pretty-printed JSON string with new lines and indentation
      const floorplanString = formattedFloorPlans.length > 0
        ? JSON.stringify(formattedFloorPlans, null, 2) // The '2' adds 2-space indentation
        : ''

      const responsesObj = {
        ...this.targetSurvey.responses,
        measurements: measurementsString,
        floorplan: floorplanString
      }

      const payload = {
        responses: responsesObj,
        inProgress: this.targetSurvey.inProgress,
        isPending: this.targetSurvey.isPending
      }

      if (this.currentUser?.uid) {
        await this.updateFirestoreCurrentQuiz(payload)
        this.setGlobalNotification('Measurements updated.')
        this.$router.push({
          path: '/projects'
        })
      }
    },
    // Initialize measurements properly from model value or target survey
    initializeMeasurements () {
      // Start with an empty object
      this.localMeasurements = {}

      const furnitureToProcess = ['rug', 'sofa', 'sideboard', 'nightstand', 'dresser', 'dining_table']

      // Get furniture list
      const furnitures = this.modelValue?.furnitures || this.targetSurvey?.responses?.furnitures || []

      // Get existing measurements
      const existingMeasurements = this.modelValue?.measurements || this.targetSurvey?.responses?.measurements || {}

      if (typeof existingMeasurements === 'string' && existingMeasurements.trim() !== '') {
        try {
          const parsedMeasurements = JSON.parse(existingMeasurements)

          // Initialize furniture measurements from parsed object
          furnitures.forEach(furniture => {
            if (!furnitureToProcess.includes(furniture)) {
              this.localMeasurements[furniture] = parsedMeasurements[furniture] || ''
            } else {
              // Dla mebli z listy do przetworzenia, używamy X i Y
              this.localMeasurements[`${furniture}A`] = parsedMeasurements[`${furniture}A`] || ''
              this.localMeasurements[`${furniture}B`] = parsedMeasurements[`${furniture}B`] || ''
            }
          })
        } catch (error) {
          console.error('Error parsing measurements string:', error)
          // Fallback to empty values
          furnitures.forEach(furniture => {
            if (!furnitureToProcess.includes(furniture)) {
              this.localMeasurements[furniture] = ''
            } else {
              this.localMeasurements[`${furniture}A`] = ''
              this.localMeasurements[`${furniture}B`] = ''
            }
          })
        }
      } else if (typeof existingMeasurements === 'object') {
        // Use object directly if it's already an object
        furnitures.forEach(furniture => {
          if (!furnitureToProcess.includes(furniture)) {
            this.localMeasurements[furniture] = existingMeasurements[furniture] || ''
          } else {
            this.localMeasurements[`${furniture}A`] = existingMeasurements[`${furniture}A`] || ''
            this.localMeasurements[`${furniture}B`] = existingMeasurements[`${furniture}B`] || ''
          }
        })
      } else {
        // Initialize with empty values if no measurements exist
        furnitures.forEach(furniture => {
          if (!furnitureToProcess.includes(furniture)) {
            this.localMeasurements[furniture] = ''
          } else {
            this.localMeasurements[`${furniture}A`] = ''
            this.localMeasurements[`${furniture}B`] = ''
          }
        })
      }

      // Process floorplan data - simplified for your known formats
      let storedFloorplan = []
      try {
        storedFloorplan = JSON.parse(this.targetSurvey?.responses?.floorplan)
      } catch (error) {
        console.log(error)
      }

      this.floorPlans = storedFloorplan
      this.initialized = true
    }
    // let parsedFloorplan = []

    // // Helper to extract CloudFront URL from string
    // const extractUrl = (str) => {
    //   if (typeof str !== 'string') return ''

    //   // Extract CloudFront URL
    //   const urlRegex = /(https?:\/\/res\.cloudinary\.com[^\s"]+)/g
    //   const matches = str.match(urlRegex)

    //   if (matches && matches.length > 0) {
    //     return matches[0].replace(/["']+$/, '')
    //   }

    //   return str
    // }

    // // Helper to generate proper ID
    // const generateId = (url) => {
    //   if (!url) return `file_${Date.now()}`

    //   // Extract filename from URL
    //   const parts = url.split('/')
    //   return parts[parts.length - 1].split('?')[0] || `file_${Date.now()}`
    // }

    // // Handle floorplan based on format
    // if (storedFloorplan) {
    //   // If it's a string (from Airtable/Firebase)
    //   if (typeof storedFloorplan === 'string') {
    //     try {
    //       // Try to parse it as JSON (our saved format)
    //       const parsed = JSON.parse(storedFloorplan)

    //       if (Array.isArray(parsed)) {
    //         // Process array of items
    //         parsedFloorplan = parsed.map(item => {
    //           // Extract URL
    //           const url = extractUrl(item.url || item.id || '')

    //           // Generate ID if needed or extract existing ID
    //           const id = (item.id && !item.id.startsWith('http'))
    //             ? item.id
    //             : generateId(url)

    //           return url ? { id, url } : null
    //         }).filter(Boolean)
    //       }
    //     } catch (error) {
    //       console.log('Not JSON, checking for URL')

    //       // Check if it's a single URL
    //       const url = extractUrl(storedFloorplan)
    //       if (url) {
    //         parsedFloorplan = [{
    //           id: generateId(url),
    //           url
    //         }]
    //       }
    //     }
    //   } else if (Array.isArray(storedFloorplan)) {
    //     parsedFloorplan = storedFloorplan.map(item => {
    //       if (typeof item === 'string') {
    //         const url = extractUrl(item)
    //         return url ? { id: generateId(url), url } : null
    //       } else if (item && typeof item === 'object') {
    //         const url = extractUrl(item.url || '')
    //         const id = (item.id && !item.id.startsWith('http'))
    //           ? item.id
    //           : generateId(url)

    //         return url ? { id, url } : null
    //       }
    //       return null
    //     }).filter(Boolean)
    //   }
    // }

    // Set the final floorPlans array
    // }
  },
  watch: {
    modelValue: {
      handler (newVal, oldVal) {
        // Check if floorplan data has changed
        const newFloorplan = newVal?.floorplan

        this.floorPlans = newFloorplan?.url != null ? newFloorplan : this.modelValue?.floorplan || this.targetSurvey?.responses?.floorplan

        // Only reinitialize if the data has actually changed
        if (!this.initialized) {
          this.initializeMeasurements()
          this.initialized = true
        }
      },
      deep: true,
      immediate: true
    },
    localMeasurements: {
      handler (val) {
        // Emit updates back to parent
        this.$emit('update:modelValue', {
          ...this.modelValue,
          measurements: val
        })
      },
      deep: true
    },
    floorPlans: {
      handler (val) {
        if (this._isRemoving) return
        // Update floorplans in model
        this.$emit('update:modelValue', {
          ...this.modelValue,
          floorplan: val
        })
      },
      deep: true
    }
  },
  async mounted () {
    try {
      // Initialize measurements from props or store
      this.initializeMeasurements()
    } catch (error) {
      console.error('Error initializing measurements:', error)
    } finally {
      this.loading = false
    }
  }

}
</script>

<style lang="scss" scoped>
.divider{
  width: 100%;
  background-color: #CED7D2;
  height: 1px;
}

.wrapper {
  padding: 0 24px;
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

.headerWrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
}

.header {
  font-family: "League Spartan", sans-serif;
  font-size: 44px;
  font-weight: 600;
  color: $tt-dark-text
}

.subheader {
  font-family: Sohne, serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;

  &--bold {
    font-family: Sohne, serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 33px;
  }
}

.fileUploadWrapper {
  width: 100%;
  @media (max-width: 1024px) {
    background: #EFF7F3;
    padding: 16px;
    border-radius: 16px;
  }
}

.description {
  font-family: Sohne, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $tt-dark-text;
  text-align: center;
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
}

.emphasized {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: $tt-dark-green;
}

.fileUploadContainer {
  display: flex;
  gap: 18px;
  width: 100%;
  background: #EFF7F3;
  border-radius: 16px;
  padding: 24px;

  @media (max-width: 1024px) {
    //flex-direction: column;
    background: transparent;
    gap: 16px;
    padding: 0;
  }
}

.labelIdleText {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.75rem !important;
  }
}

.uploadIcon {
  margin: 0 !important
}

.sectionWrapper,
.rendrSectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.sectionWrapper{
 margin-bottom: 24px;
}

.rendrSectionTitle {
  font-family: Sohne, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.93px;
  color: $tt-dark-text;
}

.sectionTitle {
  align-self: start;
  font-family: Sohne, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.93px;
  color: $tt-dark-text;
  text-align: center;
  width: 100% !important;
  margin-bottom: 9px;
}

.rendrSectionDescriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &Inner {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 12px;
    }
  }
}

.recommended {
  padding: 5px 12px 5px 12px;
  gap: 20px;
  border-radius: 16px;
  background-color: #F6FF9380;
  font-family: "Sohne", serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.38px;
  text-align: left;
}

.section,
.qrCodeWrapper {
  display: flex;
  gap: 120px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #EFF7F3;
  border-radius: 16px;
  padding: 24px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
}

.rendrLink {
  font-family: "Sohne", serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.48px;
  text-decoration: underline;
}

.qrCode {
  pointer-events: none;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
}

.qrCodeDescription {
  font-family: "Sohne", serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.29px;
  text-align: center;
}

.compatibleWithDescription {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.38px;
  text-align: left;
  margin-bottom: 8px;
  color: $tt-dark-text;
}

.compatibleWithDevices {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.38px;
  text-align: center;
  color: $tt-dark-text;
}

.scanButton {
  margin-top: 12px;
  @media (min-width: 1024px) {
    display: none;
  }
}

.sectionsDesc{
 font-size: 18px;
 font-weight: 500;
 margin: 24px 0;
}

.sizesWrapper{
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.furnitureSize{
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  max-width: 100%;
  width: 390px;
}

.furniture{
  text-transform:capitalize;
}

.sizeLabel{
  margin-bottom: 10px;
}

.textInputWrapper{
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 120px;
  align-items: end;
}

.wide{
  max-width: 270px;
}

.measurementsSave{
  width: 240px;
  margin: 24px 0;
}

.sizeSeparator{
  margin-bottom: 10px;
  margin-left: -5px;
}

.quizNavigationWrapper {
    position: sticky;
    bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    width: 100%;

    @media (max-width: 1080px) {
      justify-content: center;
      margin-top: 24px;
  }

  }

  .quiz__navigation__button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.5em 1em;
    border: none;
    cursor: pointer;
  }

  .quiz__navigation__button--back {
    background-color: #f0f0f0;
    font-weight: 500;
    height: 38px;
    text-transform: capitalize;
    font-family: 'Sohne';
    margin-bottom: 24px;

    @media (max-width: 1080px) {
    width: 272px;
  }

  }

.mobileOnly{
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
